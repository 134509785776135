import { Component, OnInit } from "@angular/core";
import { ISiteInformation } from "./site/@core/Models/companyInfo";
import { SiteService } from "./site/@core/service/site.service";

@Component({
  selector: "ngx-app",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  public allInformations: ISiteInformation;
  public linkWhatsapp: string;
  public showLoading: boolean = true;
  constructor(private siteService: SiteService) {}

  ngOnInit(): void {
    this.getCompanyInfo();
  }
  getCompanyInfo(): void {
    this.siteService
      .getGeneralInformation()
      .subscribe((informations: ISiteInformation) => {
        this.allInformations = informations;
        let numberWhatsapp: any = informations.phones.find(
          (e) => e.whatsapp == true
        );

        numberWhatsapp = numberWhatsapp
          ? this.formataNumber(numberWhatsapp.number)
          : "";
        this.linkWhatsapp = `https://wa.me/55${numberWhatsapp}?text=Ol%C3%A1%2C+vim+pelo+site+da+Conceito%21%21`;
        this.showLoading = false
      })
  }
  formataNumber(number: string) {
    return number.match(/\d/g).join("");
  }
}
