import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  // isLoading = new Subject<boolean>();
  isLoading

  constructor() { }

  show() {
    this.isLoading = true
  }

  hide() {
    this.isLoading = false
  }
}
