<div id="navbar" class="topnav">
  <div style="width: 12%"></div>
  <div class="topbar-menu">
    <div id="topbar-menu-left" class="topbar-menu-left">
      <p class="topbar-menu-left-item mr-5" (click)="goToSection('sobre')">
        Sobre nós
      </p>
      <p
        class="topbar-menu-left-item ml-5"
        (click)="goToSection('ambientes')"
        *ngIf="rotaAtual === ''"
      >
        Ambientes
      </p>
      <div class="dropdown" *ngIf="rotaAtual === 'ambiente'">
        <p
          class="topbar-menu-left-item ml-5"
          (click)="goToSection('ambientes')"
        >
          Ambientes
        </p>
        <div class="dropdown-content">
          <div class="dropdown-content-item" *ngFor="let ambiente of ambientes">
            <p
              class="topbar-menu-left-item"
              (click)="redirectPage(ambiente.name)"
            >
              {{ ambiente.name }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div id="center" class="topbar-menu-center mr-10 ml-10">
      <svg viewBox="0 0 211 84" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M20.4099 2.38833e-05L115.351 0H210.293L179.573 59.397L105.41 84L31.2464 59.397L0.526855 0L20.4099 2.38833e-05Z"
          fill="#737272"
          fill-opacity="0.8"
        />
      </svg>
      <img src="../../../../../assets/images/logos/logo2-camada.png" />
    </div>
    <div class="topbar-menu-right">
      <p
        class="topbar-menu-right-item mr-5"
        (click)="goToSection('depoimentos')"
      >
        Depoimentos
      </p>
      <p class="topbar-menu-right-item ml-5" (click)="redirectPageContato()">
        Contato
      </p>
    </div>
  </div>
  <div
    id="social-media"
    class="col-md-1 justify-content-end"
    style="display: none"
  >
    <div class="topnav-social-media">
      <a
        href="{{ facebookLink }}"
        target="_blank"
        class="ion ion-social-facebook float-end text-white"
      ></a>
      <a
        href="{{ whatsappLink }}"
        target="_blank"
        class="ion ion-social-whatsapp float-end text-white"
      ></a>
      <a
        href="{{ instagramLink }}"
        target="_blank"
        class="ion ion-social-instagram float-end text-white"
      ></a>
    </div>
  </div>
</div>
