<div class="whats-app-button">
  <svg
    class="icon-whats"
    (click)="sendMessageToWhats()"
    width="62"
    height="60"
    viewBox="0 0 62 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_76_44)">
      <path
        d="M1.43108 60C1.08518 60 0.74868 59.8668 0.499127 59.621C0.170147 59.2969 0.0432555 58.8263 0.166858 58.3864L4.17147 44.1426C1.67828 39.7513 0.363775 34.7836 0.365655 29.7409C0.372704 13.3415 14.0742 0 30.9095 0C39.0752 0.00320435 46.7479 3.10135 52.5149 8.72269C58.281 14.3445 61.4552 21.8175 61.4528 29.7647C61.4453 46.1646 47.7438 59.507 30.9095 59.507C30.9095 59.507 30.8968 59.507 30.8963 59.507C25.979 59.5052 21.104 58.3379 16.7634 56.1269L1.76335 59.9583C1.65337 59.9863 1.54152 60 1.43108 60V60Z"
        fill="#E5E5E5"
      />
      <path
        d="M1.43115 58.7232L5.57818 43.9727C3.02013 39.6555 1.6746 34.7584 1.67648 29.7413C1.68306 14.046 14.7962 1.27672 30.9096 1.27672C38.729 1.27993 46.0686 4.2444 51.5879 9.62494C57.1068 15.0055 60.1452 22.1576 60.1423 29.7642C60.1353 45.4591 47.0203 58.2302 30.9096 58.2302C30.9087 58.2302 30.9101 58.2302 30.9096 58.2302H30.897C26.0045 58.2283 21.1977 57.0331 16.928 54.7649L1.43115 58.7232ZM17.6456 49.6119L18.5334 50.1241C22.2631 52.2802 26.5394 53.4205 30.9002 53.4227H30.9096C44.301 53.4227 55.2006 42.8081 55.2062 29.7624C55.2086 23.4402 52.6839 17.4953 48.0965 13.0234C43.5086 8.55149 37.4089 6.08735 30.9186 6.0846C17.5164 6.0846 6.61729 16.6978 6.61212 29.7431C6.61024 34.2137 7.8942 38.567 10.3268 42.3344L10.9048 43.2297L8.44971 51.9602L17.6456 49.6119Z"
        fill="white"
      />
      <path
        d="M2.44751 57.7295L6.45119 43.4889C3.98102 39.3214 2.68249 34.5932 2.6839 29.7505C2.69001 14.5985 15.3496 2.27142 30.9042 2.27142C38.4538 2.27463 45.5396 5.13657 50.8667 10.3308C56.1952 15.5251 59.1278 22.4295 59.125 29.772C59.1184 44.9245 46.4584 57.253 30.9056 57.253C30.9042 57.253 30.9061 57.253 30.9056 57.253H30.8929C26.1702 57.2511 21.5292 56.0967 17.4076 53.9085L2.44751 57.7295Z"
        fill="#64B161"
      />
      <path
        d="M1.43115 58.7232L5.57818 43.9727C3.02013 39.6555 1.6746 34.7584 1.67648 29.7413C1.68306 14.046 14.7962 1.27672 30.9096 1.27672C38.729 1.27993 46.0686 4.2444 51.5879 9.62494C57.1068 15.0055 60.1452 22.1576 60.1423 29.7642C60.1353 45.4591 47.0203 58.2302 30.9096 58.2302C30.9087 58.2302 30.9101 58.2302 30.9096 58.2302H30.897C26.0045 58.2283 21.1977 57.0331 16.928 54.7649L1.43115 58.7232ZM17.6456 49.6119L18.5334 50.1241C22.2631 52.2802 26.5394 53.4205 30.9002 53.4227H30.9096C44.301 53.4227 55.2006 42.8081 55.2062 29.7624C55.2086 23.4402 52.6839 17.4953 48.0965 13.0234C43.5086 8.55149 37.4089 6.08735 30.9186 6.0846C17.5164 6.0846 6.61729 16.6978 6.61212 29.7431C6.61024 34.2137 7.8942 38.567 10.3268 42.3344L10.9048 43.2297L8.44971 51.9602L17.6456 49.6119Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.6034 17.8414C23.0563 16.6567 22.4801 16.6329 21.9594 16.6123C21.5336 16.5944 21.0463 16.5953 20.5598 16.5953C20.0729 16.5953 19.2815 16.7739 18.6123 17.4857C17.9426 18.1975 16.0552 19.9187 16.0552 23.4192C16.0552 26.9202 18.6729 30.3027 19.0381 30.7778C19.4032 31.2525 24.0912 38.6656 31.5153 41.5175C37.6855 43.8878 38.9408 43.4163 40.2802 43.2977C41.6196 43.1792 44.6016 41.5765 45.2102 39.9153C45.8193 38.2541 45.8193 36.8304 45.6365 36.5324C45.4537 36.2362 44.9668 36.0582 44.2364 35.7025C43.5061 35.3468 39.9151 33.6251 39.2454 33.388C38.5757 33.1504 38.0888 33.0323 37.6019 33.7446C37.1145 34.456 35.7159 36.0582 35.2896 36.5324C34.8634 37.008 34.4371 37.0675 33.7068 36.7114C32.9764 36.3548 30.6238 35.6041 27.8331 33.1802C25.6618 31.2946 24.1955 28.9655 23.7693 28.2533C23.3435 27.5419 23.7622 27.1903 24.0902 26.8017C24.681 26.1018 25.6722 24.8434 25.9156 24.3691C26.1591 23.8939 26.0373 23.4788 25.855 23.1226C25.6722 22.7669 24.2524 19.2485 23.6034 17.8414V17.8414Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_76_44">
        <rect width="61.6" height="60" fill="white" />
      </clipPath>
    </defs>
  </svg>
</div>
