import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "../../../../../environments/environment";
import { IAmbientes, IAmbientesItens } from "../../../@core/models/room";
import { SiteService } from "../../../@core/service/site.service";
import { AmbientePageComponent } from "../../../pages/ambiente-page/ambiente-page.component";

@Component({
  selector: "ngx-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  rotaAtual = "";
  public urlImage: string = environment.api.urlImage;
  public ambientes: IAmbientesItens;
  @Input() logo: string;
  @Input() facebookLink: string;
  @Input() instagramLink: string;
  @Input() whatsappLink: string;
  constructor(
    public route: Router,
    public activatedRoute: ActivatedRoute,
    public siteService: SiteService
  ) {}

  ngOnInit() {
    if (window.location.href.split("/")[3] === "ambiente") {
      this.rotaAtual = window.location.href.split("/")[3];
      this.getAmbientes();
    }
    document.getElementById("center").style.display = "none";
    document.addEventListener("scroll", () => {
      this.scrollNavBar();
    });
  }
  scrollNavBar() {
    if (window.scrollY > 100) {
      document.getElementById("center").style.display = "contents";
      document.getElementById("topbar-menu-left").style.marginRight = "150px";
    } else {
      document.getElementById("topbar-menu-left").style.marginRight = "0";
      document.getElementById("center").style.display = "none";
    }
  }

  getAmbientes() {
    this.siteService.getAmbience().subscribe((res: any) => {
      this.ambientes = res.Ativo;
    });
  }

  redirectPage(name) {
    window.location.href = `/ambiente/${name}`;
  }
  redirectPageContato() {
    this.route.navigateByUrl(`/contatos`);
  }
  goToSection(section: string, event?: any) {
    if (
      window.location.href.split("/")[3] === "contatos" ||
      window.location.href.split("/")[3] === "ambiente"
    ) {
      this.route.navigateByUrl(`/`);
    } else {
      const element = document.getElementById(section);
      const to = element.offsetTop;
      window.scroll({
        top: to - 100,
        behavior: "smooth",
      });
    }
  }
}
