import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "ngx-whats-app-button",
  templateUrl: "./whats-app-button.component.html",
  styleUrls: ["./whats-app-button.component.scss"],
})
export class WhatsAppButtonComponent implements OnInit {
  constructor() {}
  @Input() whatsappLink: string;
  ngOnInit(): void {}
  sendMessageToWhats() {
    window.open(this.whatsappLink, "_blank");
  }
}
