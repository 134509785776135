import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NbIconModule, NbLayoutModule, NbSidebarModule } from "@nebular/theme";
import { CarouselModule } from "ngx-bootstrap/carousel";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { SideBarMenuComponent } from "./components/side-bar-menu/side-bar-menu.component";
import { WhatsAppButtonComponent } from "./components/whats-app-button/whats-app-button.component";
import { FooterComponent } from "./components/footer/footer.component";
import { BackToTopComponent } from "./components/back-to-top/back-to-top.component";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NbEvaIconsModule } from "@nebular/eva-icons";
import { PhoneFormatPipe } from "./pipes/phone-format.pipe";
import { LoaderComponent } from './components/loader/loader.component';
import { LazyLoadImageModule } from "ng-lazyload-image";

@NgModule({
  imports: [
    CommonModule,
    CarouselModule.forRoot(),
    FormsModule,
    NbSidebarModule.forRoot(),
    NbLayoutModule,
    RouterModule,
    NbIconModule,
    NbEvaIconsModule,
    LazyLoadImageModule
  ],
  declarations: [
    NavbarComponent,
    SideBarMenuComponent,
    WhatsAppButtonComponent,
    FooterComponent,
    BackToTopComponent,
    PhoneFormatPipe,
    LoaderComponent,
  ],
  exports: [
    NavbarComponent,
    SideBarMenuComponent,
    WhatsAppButtonComponent,
    FooterComponent,
    BackToTopComponent,
    LoaderComponent
  ],
})
export class ThemeModule {}
