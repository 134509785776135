<nav class="navbar sticky-bottom">
  <div
    class="row m-0 align-items-center flex-wrap footer-section justify-content-center"
  >
    <div class="col-md-7 col-12 ms-md-5 row infomacao">
      <div class="row justify-content-center">
        <div class="col-12 row sticky-bottom-contato align-items-center">
          <div
            class="col-md-4 col-12 d-flex align-items-center justify-content-start sticky-bottom-telefone"
          >
            <i
              class="ion ion-ios-telephone-outline float-end text-white mr-3 sticky-bottom-telefone-icon"
            ></i>
            <div
              class="align-self-start align-items-center mt-md-2"
              style="line-height: 0.5rem"
            >
              <p
                *ngFor="let phone of informations?.phones; index as i"
                class="text-white"
              >
                {{ phone.number | phoneFormat }}
              </p>
            </div>
          </div>
          <div
            class="col-md-4 col-12 d-flex align-items-center justify-content-start sticky-bottom-email"
          >
            <i
              class="ion ion-ios-email-outline float-end text-white mr-2 sticky-bottom-email-icon"
            ></i>
            <div
              class="align-self-start align-items-center mt-md-2"
              style="line-height: 0.5rem"
            >
              <p class="text-white">{{ informations?.email_contact }}</p>
            </div>
          </div>
        </div>
        <div class="col-12 sticky-bottom-informacoes row">
          <div
            class="col-md-4 col-12 d-flex sticky-bottom-informacoes-social-media"
          >
            <i
              class="ion ion-social-instagram float-end text-white sticky-bottom-informacoes-social-media-icon-instagram"
            ></i>
            <p class="text-white ms-2">
              <a
                href="{{ informations?.instagram }}"
                target="_blank"
                class="text-white"
                style="text-decoration: none"
              >
                @conceito_waw
              </a>
            </p>
          </div>
          <div class="d-flex justify-content-start col-md-6 col-12">
            <i
              class="ion ion-ios-location-outline float-end text-white mr-3 sticky-bottom-informacoes-location-icon"
            ></i>
            <p class="text-white">
              {{ informations?.address?.public_place }},
              {{ informations?.address?.district }} -
              {{ informations?.address?.city }} - Paraíba,
              {{ informations?.address?.cep }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4 col-12 d-flex justify-content-center">
      <img
        [lazyLoad]="'../../../../../assets/images/footer/logo3-footer.png'"
        class="sticky-bottom-logo"
      />
    </div>
  </div>

  <div class="bottom-3m">
    <div class="d-flex justify-content-center">
      <div>
        <img
          [lazyLoad]="'../../../../../assets/images/general_images/3mlogo.png'"
          class="bottom-3m-image"
        />
        <p class="text-white text-center bottom-3m-text">
          2022 - Direitos reservados
        </p>
      </div>
    </div>
  </div>
</nav>
