import { ExtraOptions, RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
console.log("estou no app routing");

export const routes: Routes = [
  {
    path: "",
    loadChildren: () => import("./site/site.module").then((m) => m.SiteModule),
  },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
