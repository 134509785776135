<loader *ngIf="showLoading"></loader>
<div class="ambiente" *ngIf="ambiente">
  <div class="ambiente-title">
    <div class="ambiente-title-overlay"></div>
    <img
      [lazyLoad]="urlApiImg + '/' +imgCapa"
      class="img-fluid ambiente-title-img-background"
      alt=""
    />
    <div class="ambiente-name">
      <span>{{ ambiente.name }}</span>
      <img
        [lazyload]="'../../../../assets/images/general_images/listra-branca.png'"
        alt=""
      />
    </div>
  </div>
  <div class="ambiente-fotos p-5 row w-100">
    <div
      class="col-md-4 mb-4"
      *ngFor="let photo of ambiente.photos;"
    >
      <a data-fancybox="gallery" href="{{ urlApiImg }}/{{ photo.name }}">
        <img class="rounded img-fluid" [lazyLoad]=" urlApiImg + '/' + photo.name " />
      </a>
    </div>
  </div>
</div>
