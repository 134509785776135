import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import {
  NbChatModule,
  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbThemeModule,
  NbToastrModule,
  NbWindowModule,
} from "@nebular/theme";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ThemeModule } from "./site/@theme/theme.module";
import { AmbientePageComponent } from "./site/pages/ambiente-page/ambiente-page.component";
import { LazyLoadImageModule } from "ng-lazyload-image";

@NgModule({
  declarations: [AppComponent, AmbientePageComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NbToastrModule.forRoot(),
    NgbModule,
    ThemeModule,
    NbThemeModule.forRoot(),
    LazyLoadImageModule

  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
