import { Component, OnInit } from "@angular/core";

@Component({
  selector: "ngx-back-to-top",
  templateUrl: "./back-to-top.component.html",
  styleUrls: ["./back-to-top.component.scss"],
})
export class BackToTopComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    document.addEventListener("scroll", () => {
      this.showButtonGoToTop();
    });
  }

  showButtonGoToTop() {
    const buttonTop = document.getElementById("buttonGoTop");
    if (window.scrollY > 0 && !buttonTop.classList.contains("showButton")) {
      buttonTop.classList.add("showButton");
    } else if (
      window.scrollY <= 0 &&
      buttonTop.classList.contains("showButton")
    ) {
      buttonTop.classList.remove("showButton");
    }
  }

  goToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
}
