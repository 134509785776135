<loader *ngIf="showLoading"></loader>
<div class="static-buttons-section">
  <ngx-whats-app-button [whatsappLink]="linkWhatsapp"></ngx-whats-app-button>
  <ngx-back-to-top></ngx-back-to-top>
</div>
<ngx-navbar
  [logo]="allInformations?.logo"
  [facebookLink]="allInformations?.facebook"
  [whatsappLink]="linkWhatsapp"
  [instagramLink]="allInformations?.instagram"
></ngx-navbar>
<ngx-side-bar-menu
  [logo]="allInformations?.logo"
  [facebookLink]="allInformations?.facebook"
  [whatsappLink]="linkWhatsapp"
  [instagramLink]="allInformations?.instagram"
></ngx-side-bar-menu>

<router-outlet></router-outlet>

<ngx-footer
  [informations]="allInformations"
  [linkWhatsapp]="linkWhatsapp"
></ngx-footer>
