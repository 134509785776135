import { Component, Input, OnInit } from "@angular/core";
import { environment } from "../../../../../environments/environment";
import { ISiteInformation } from "../../../@core/Models/companyInfo";

@Component({
  selector: "ngx-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  public urlImage = environment.api.urlImage;
  @Input() informations: ISiteInformation;
  @Input() linkWhatsapp: string;
  constructor() {}

  ngOnInit(): void {}
}
