import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { environment } from "../../../../environments/environment";
import { IAmbience, IAmbienceItem } from "../models/ambience";
import { IDepoimentos } from "../Models/depoimentos";
import { ISiteInformation } from "../Models/companyInfo";
import { ISendMessageRequest } from "../Models/sendMessageRequest";
import { IBanner } from "../Models/banner";
import { IImages } from "../Models/images";

@Injectable({
  providedIn: "root",
})
export class SiteService {
  public apiUrl = environment.api.urlHK;
  constructor(readonly http: HttpClient, readonly router: Router) {}

  readonly environmentURL = environment.api.urlHK;

  getAllDepoimentos(): Observable<IDepoimentos> {
    return this.http.get<IDepoimentos>(`${this.environmentURL}deposition`);
  }

  getAmbience(): Observable<IAmbience> {
    return this.http.get<IAmbience>(`${this.environmentURL}ambience`);
  }
  getAmbienceName(name): Observable<IAmbienceItem> {
    return this.http.get<IAmbienceItem>(
      `${this.environmentURL}getambience/${name}`
    );
  }
  sendMessage(messageRequest: ISendMessageRequest): Observable<any> {
    return this.http.post(
      `${this.environmentURL}email-contact`,
      messageRequest
    );
  }

  getAllBanners(): Observable<IBanner> {
    return this.http.get<IBanner>(`${this.environmentURL}banner`);
  }
  getGeneralInformation(): Observable<ISiteInformation> {
    return this.http.get<ISiteInformation>(`${this.apiUrl}company-info`);
  }
  getImages(): Observable<IImages> {
    return this.http.get<IImages>(`${this.apiUrl}image`);
  }
}
