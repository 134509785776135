import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { environment } from "../../../../environments/environment";
import { IAmbienceItem } from "../../@core/models/ambience";
import { SiteService } from "../../@core/service/site.service";
import { Fancybox } from "@fancyapps/ui/src/Fancybox/Fancybox.js";
@Component({
  selector: "ngx-ambiente-page",
  templateUrl: "./ambiente-page.component.html",
  styleUrls: ["./ambiente-page.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AmbientePageComponent implements OnInit {
  constructor(
    private siteService: SiteService,
    public router: ActivatedRoute
  ) {}
  public showLoading: boolean = true;
  public ambiente: IAmbienceItem;
  public imgCapa;
  public urlApiImg = environment.api.urlImage;

  ngOnInit(): void {
    this.getAmbiente();
    this.getFancybox();
  }

  async getAmbiente() {
    this.siteService
      .getAmbienceName(this.router.snapshot.paramMap.get("name"))
      .toPromise().then((photoAmbiente) => {
        let capa: any = photoAmbiente.photos.filter((photo) => photo.cover == 1);
          this.imgCapa = capa.length > 0 ? capa[0].name : photoAmbiente.photos[0].name;
          this.ambiente = photoAmbiente;
          this.showLoading = false
      })
  }

  getFancybox() {
    Fancybox.bind('[data-fancybox="gallery"]', {
      Thumbs: {
        Carousel: {
          fill: false,
          center: true,
        },
      },
    });
  }

}
