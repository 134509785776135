import { LoaderService } from './../../../@core/service/load/loader.service';
import { Subject } from 'rxjs';
import { Component } from '@angular/core';

@Component({
  selector: 'loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
  // isLoading: Subject<boolean> = this.loaderService.isLoading;
  isLoading: boolean = this.loaderService.isLoading;

  constructor(private loaderService: LoaderService) {

  }

}
