import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "../../../../../environments/environment";
import { IAmbientesItens } from "../../../@core/models/room";
import { SiteService } from "../../../@core/service/site.service";

@Component({
  selector: "ngx-side-bar-menu",
  templateUrl: "./side-bar-menu.component.html",
  styleUrls: ["./side-bar-menu.component.scss"],
})
export class SideBarMenuComponent implements OnInit {
  rotaAtual = "";
  public urlImage: string = environment.api.urlImage;
  public menu: any;
  public buttonOpenMenu: any;
  public buttonCloseMenu: any;
  public ambientes: IAmbientesItens;
  @Input() logo: string;
  @Input() facebookLink: string;
  @Input() instagramLink: string;
  @Input() whatsappLink: string;
  public isContactPage: boolean = false;
  constructor(public route: Router, public siteService: SiteService) {}

  ngOnInit(): void {
    this.menu = document.getElementById("side-bar-controll");
    this.buttonCloseMenu = document.getElementById("close-menu");
    this.buttonOpenMenu = document.getElementById("open-menu");
    if (window.location.href.split("/")[3] === "ambiente") {
      this.rotaAtual = window.location.href.split("/")[3];
    }
    this.getAmbientes();
    if (window.location.href.split("/")[3] === "contatos") {
      this.isContactPage = true;
    }
  }
  getAmbientes() {
    this.siteService.getAmbience().subscribe((res: any) => {
      this.ambientes = res.Ativo;
    });
  }
  goToSection(section: string, event?: any) {
    if (window.location.href.split("/")[3] === "contatos") {
      this.route.navigateByUrl(`/`);
      this.isContactPage = false;
    } else if (window.location.href.split("/")[3] === "ambiente") {
      this.route.navigateByUrl(`/`);
    } else {
      const element = document.getElementById(section);
      const to = element.offsetTop;
      window.scroll({
        top: to - 100,
        behavior: "smooth",
      });
      if (this.menu.classList.contains("active")) {
        this.menu.classList.remove("active");
        this.buttonCloseMenu.classList.remove("show");
        this.buttonOpenMenu.classList.add("show");
      }
    }
  }

  openMenu() {
    if (
      !this.menu.classList.contains("active") &&
      this.buttonOpenMenu.classList.contains("show")
    ) {
      this.menu.classList.add("active");
      this.buttonOpenMenu.classList.remove("show");
      this.buttonCloseMenu.classList.add("show");
    } else if (this.menu.classList.contains("active")) {
      this.menu.classList.remove("active");
      this.buttonCloseMenu.classList.remove("show");
      this.buttonOpenMenu.classList.add("show");
    }
  }
  redirectPageContato() {
    this.isContactPage = true;
    this.route.navigateByUrl(`/contatos`);
  }
  redirectPage(name) {
    window.location.href = `/ambiente/${name}`;
  }
  menuAmbiente(action) {
    const iconAbrir = document.getElementById("arrow-right");
    const iconfechar = document.getElementById("arrow-down");
    const menuAmbiente = document.getElementById("menuAmbiente");
    if (action == "abrir") {
      iconAbrir.classList.add("d-none");
      iconfechar.classList.remove("d-none");
      menuAmbiente.classList.remove("d-none");
    } else {
      iconAbrir.classList.remove("d-none");
      iconfechar.classList.add("d-none");
      menuAmbiente.classList.add("d-none");
    }
  }
}
