<div class="responsive-menu">
  <div class="show-logo-toggle-button">
    <div class="navbar-brand">
      <img src="" class="topnav-logo" />
    </div>
    <div (click)="openMenu()" class="menu-toggle">
      <nb-icon
        class="icon-toggle-menu show"
        id="open-menu"
        icon="menu-outline"
      ></nb-icon>
      <nb-icon
        class="icon-toggle-menu"
        id="close-menu"
        icon="close-outline"
      ></nb-icon>
    </div>
  </div>
  <div class="side-bar" id="side-bar-controll">
    <div class="menu-itens" style="overflow: auto">
      <div (click)="goToSection('sobre')" style="color: #ffff">Sobre nós</div>
      <div class="dropdown" style="color: #ffff">
        <div class="d-flex">
          <p
            class="topbar-menu-left-item mb-0 me-1"
            (click)="goToSection('ambientes')"
          >
            Ambientes
          </p>
          <nb-icon
            id="arrow-down"
            class="d-none"
            style="width: 30px"
            icon="arrow-ios-downward"
            (click)="menuAmbiente('fecha')"
          ></nb-icon>
          <nb-icon
            id="arrow-right"
            class=""
            style="width: 30px"
            icon="arrow-ios-forward"
            (click)="menuAmbiente('abrir')"
          ></nb-icon>
        </div>

        <div class="d-none mt-1 ms-4" id="menuAmbiente">
          <div class="mt-1" *ngFor="let ambiente of ambientes">
            <p
              class="topbar-menu-left-item"
              (click)="redirectPage(ambiente.name)"
            >
              {{ ambiente.name }}
            </p>
          </div>
        </div>
      </div>
      <div (click)="goToSection('depoimentos')" style="color: #ffff">
        Depoimentos
      </div>
      <div (click)="redirectPageContato()" style="color: #ffff">Contato</div>
    </div>
    <hr />
    <div class="d-flex justify-content-around topnav-social-media">
      <a
        href="{{ facebookLink }}"
        target="_blank"
        class="ion ion-social-facebook float-end text-white"
      ></a>
      <a
        href="{{ whatsappLink }}"
        target="_blank"
        class="ion ion-social-whatsapp float-end text-white"
      ></a>
      <a
        href="{{ instagramLink }}"
        target="_blank"
        class="ion ion-social-instagram float-end text-white"
      ></a>
    </div>
  </div>
</div>
